import React, { useEffect, useRef, useState } from 'react';
import type { RefObject } from 'react';
import { createPortal } from 'react-dom';
import invariant from 'tiny-invariant';

import DropdownMenu from '@atlaskit/dropdown-menu';
import { Box, Flex, Inline, Text, xcss } from '@atlaskit/primitives';
import {
	attachClosestEdge,
	type Edge,
	extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { DragHandleButton } from '@atlaskit/pragmatic-drag-and-drop-react-accessibility/drag-handle-button';
import { DropIndicator } from '@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
	draggable,
	dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';

import { iconByMenuId } from '../GlobalNavigationIcons';

import { getItemData, isItemData } from './customizeDraggableData';
import { CustomizeDraggableItemActions } from './CustomizeDraggableItemActions';
import { SEPARATOR_ID } from './CustomizeSidebarDialog';

type Item = {
	id: string;
};

type DraggableState =
	| { type: 'idle' }
	| { type: 'preview'; container: HTMLElement }
	| { type: 'dragging' };

const draggingState: DraggableState = { type: 'dragging' };
const idleState: DraggableState = { type: 'idle' };

type CustomizeDraggableListProps = {
	item: Item;
	index: number;
	label: string;
	onReorderItem: (startIndex: number, finishIndex: number) => void;
	numberOfItems: number;
};

export function CustomizeDraggableItem({
	item,
	index,
	label,
	onReorderItem,
	numberOfItems,
}: CustomizeDraggableListProps) {
	const ref = useRef<HTMLDivElement | null>(null);
	const [draggableState, setDraggableState] = useState<DraggableState>(idleState);
	const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

	useEffect(() => {
		const element = ref.current;
		invariant(element);

		const data = getItemData({ item, index });

		return combine(
			draggable({
				element,
				getInitialData: () => data,
				onDragStart() {
					setDraggableState(draggingState);
				},
				onDrop() {
					setDraggableState(idleState);
				},
			}),
			dropTargetForElements({
				element,
				canDrop({ source }) {
					return isItemData(source.data);
				},
				getData({ input }) {
					return attachClosestEdge(data, {
						element,
						input,
						allowedEdges: ['top', 'bottom'],
					});
				},
				onDrag({ self, source }) {
					if (source.element === element) {
						setClosestEdge(null);
						return;
					}

					const newClosestEdge = extractClosestEdge(self.data);
					const sourceIndex = source.data.index;

					invariant(typeof sourceIndex === 'number');

					const isItemBeforeSource = index === sourceIndex - 1;
					const isItemAfterSource = index === sourceIndex + 1;

					const isDropIndicatorHidden =
						(isItemBeforeSource && newClosestEdge === 'bottom') ||
						(isItemAfterSource && newClosestEdge === 'top');

					if (isDropIndicatorHidden) {
						setClosestEdge(null);
						return;
					}

					setClosestEdge(newClosestEdge);
				},
				onDragLeave() {
					setClosestEdge(null);
				},
				onDrop() {
					setClosestEdge(null);
				},
			}),
		);
	}, [index, item, setDraggableState, setClosestEdge]);

	const isSeparator = item.id === SEPARATOR_ID;

	return (
		<>
			<Box ref={ref} xcss={containerStyles}>
				<Flex
					alignItems="center"
					justifyContent="start"
					xcss={[
						wrapperStyles,
						// We apply the disabled effect to the inner element so the drop indicator is not affected.
						draggableState.type === 'dragging' && disabledStyles,
					]}
				>
					<DropdownMenu
						shouldRenderToParent
						trigger={({ triggerRef, ...triggerProps }) => (
							<DragHandleButton
								{...triggerProps}
								// DragHandleButton expects a HTMLButtonElement, but DropdownMenu passes a HTMLElement.
								// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
								ref={triggerRef as RefObject<HTMLButtonElement>}
								appearance="subtle"
								label={label}
							/>
						)}
					>
						<CustomizeDraggableItemActions
							index={index}
							onReorderItem={onReorderItem}
							numberOfItems={numberOfItems}
						/>
					</DropdownMenu>
					{isSeparator ? SeparatorItem(label) : ItemComponent(iconByMenuId[item.id], label)}
				</Flex>
				{closestEdge && <DropIndicator edge={closestEdge} data-testid="drop-indicator" />}
			</Box>
			{draggableState.type === 'preview' &&
				createPortal(
					<Box xcss={previewStyles}>
						{isSeparator ? SeparatorItem(label) : ItemComponent(iconByMenuId[item.id], label)}
					</Box>,
					draggableState.container,
				)}
		</>
	);
}

const ItemComponent = (icon, label) => {
	return (
		<Inline alignBlock="center" grow="fill" space="space.100">
			{icon}
			<Text>{label}</Text>
		</Inline>
	);
};

const SeparatorItem = (label) => {
	return (
		<Inline alignBlock="center" grow="fill" space="space.100">
			<Box xcss={lineStyles} />
			<Text color="color.text.subtlest" size="UNSAFE_small" weight="medium">
				{label}
			</Text>
			<Box xcss={lineStyles} />
		</Inline>
	);
};

const containerStyles = xcss({
	cursor: 'grab',
	position: 'relative',
});

const wrapperStyles = xcss({
	flexGrow: 1,
	overflow: 'hidden',
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',

	':hover': {
		backgroundColor: 'color.background.neutral.subtle.hovered',
	},
});

const disabledStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
	opacity: 0.4,
});

const previewStyles = xcss({
	backgroundColor: 'color.background.neutral.subtle.hovered',
	borderRadius: 'border.radius.100',
	opacity: 0.5,
	overflow: 'hidden',
	paddingBlock: 'space.050',
	paddingInline: 'space.100',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

const lineStyles = xcss({
	flex: 1,
	borderBlockEndWidth: 'border.width',
	borderBlockEndColor: 'color.border.bold',
	borderBlockEndStyle: 'dashed',
});
