import React, { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';

type CustomizeDraggableItemActionsProps = {
	index: number;
	onReorderItem: (startIndex: number, finishIndex: number) => void;
	numberOfItems: number;
};

export const CustomizeDraggableItemActions = ({
	index,
	onReorderItem,
	numberOfItems,
}: CustomizeDraggableItemActionsProps) => {
	const intl = useIntl();
	const lastItemIndex = numberOfItems - 1;

	const isMoveUpDisabled = index === 0;
	const isMoveDownDisabled = index === lastItemIndex;

	const moveToTop = useCallback(() => {
		onReorderItem(index, 0);
	}, [index, onReorderItem]);

	const moveToBottom = useCallback(() => {
		onReorderItem(index, lastItemIndex);
	}, [index, lastItemIndex, onReorderItem]);

	const moveUp = useCallback(() => {
		onReorderItem(index, index - 1);
	}, [index, onReorderItem]);

	const moveDown = useCallback(() => {
		onReorderItem(index, index + 1);
	}, [index, onReorderItem]);

	return (
		<DropdownItemGroup>
			<DropdownItem onClick={moveToTop} isDisabled={isMoveUpDisabled}>
				{intl.formatMessage(i18n.moveToTop)}
			</DropdownItem>
			<DropdownItem onClick={moveUp} isDisabled={isMoveUpDisabled}>
				{intl.formatMessage(i18n.moveUp)}
			</DropdownItem>
			<DropdownItem onClick={moveDown} isDisabled={isMoveDownDisabled}>
				{intl.formatMessage(i18n.moveDown)}
			</DropdownItem>
			<DropdownItem onClick={moveToBottom} isDisabled={isMoveDownDisabled}>
				{intl.formatMessage(i18n.moveToBottom)}
			</DropdownItem>
		</DropdownItemGroup>
	);
};

const i18n = defineMessages({
	moveToTop: {
		id: 'side-navigation.customize-sidebar.item-actions.move-to-top',
		defaultMessage: 'Move to top',
		description: 'Label of drag and drop menu item for moving item to the top of the list',
	},
	moveUp: {
		id: 'side-navigation.customize-sidebar.item-actions.move-up',
		defaultMessage: 'Move up',
		description: 'Label of drag and drop menu item for moving item one placement up',
	},
	moveDown: {
		id: 'side-navigation.customize-sidebar.item-actions.move-down',
		defaultMessage: 'Move down',
		description: 'Label of drag and drop menu item for moving item one placement down',
	},
	moveToBottom: {
		id: 'side-navigation.customize-sidebar.item-actions.move-to-bottom',
		defaultMessage: 'Move to bottom',
		description: 'Label of drag and drop menu item for moving item to the bottom of the list',
	},
});
