import type { CustomizableListItem } from './CustomizeSidebarDialog';

export const itemDataKey = Symbol('item');

type ItemData = { [itemDataKey]: true; item: CustomizableListItem; index: number };

export function getItemData({
	item,
	index,
}: {
	item: CustomizableListItem;
	index: number;
}): ItemData {
	return {
		[itemDataKey]: true,
		item,
		index,
	};
}

export function isItemData(data: Record<string | symbol, unknown>): data is ItemData {
	return data[itemDataKey] === true;
}
