import type { FC, ReactNode } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Popup, PopupContent, PopupTrigger } from '@atlaskit/popup/experimental';
import { Box, xcss } from '@atlaskit/primitives';

import { MenuButtonItem } from '@atlassian/navigation-system/side-nav/menu-button-item';
import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';
import { Divider } from '@atlassian/navigation-system/side-nav/menu-section';

import { fg } from '@confluence/feature-gating';

import { GlobalItemLoadables } from './GlobalItems/GlobalItemLoadables';
import { VisuallyHiddenDivider } from './GlobalItems/VisuallyHiddenDivider';
import { i18n } from './globalNavigationTranslations';
import { iconByMenuId, MoreIconComponent } from './GlobalNavigationIcons';
import type { MenuId, MoreMenuItemConfig } from './useMenuItems';

type MoreMenuItemProps = {
	moreMenuItems: MoreMenuItemConfig;
	isOpen: boolean;
	toggleMenu: () => void;
	onClick: (menuId: MenuId) => void;
	peekingId?: MenuId;
};

export const MoreMenuItem: FC<MoreMenuItemProps> = ({
	moreMenuItems,
	isOpen,
	toggleMenu,
	onClick,
	peekingId,
}) => {
	if (!moreMenuItems || moreMenuItems?.length <= 0) {
		return null;
	}
	const popup = (
		<Popup isOpen={isOpen}>
			<PopupTrigger>
				{(triggerProps) => (
					<MenuButtonItem {...triggerProps} elemBefore={MoreIconComponent} onClick={toggleMenu}>
						<FormattedMessage {...i18n.more} />
					</MenuButtonItem>
				)}
			</PopupTrigger>

			{/* Adding listitem role as the parent is a list. Not using the `li` element so it doesn't inherit default styles. */}
			<Box xcss={popupContentContainerStyles} role="listitem">
				<PopupContent
					placement="bottom-start"
					// Offset the top margin of the menu section.
					// This will keep an even spacing of menu items between those inside the dropdown and those above,
					// and keep the icons aligned
					offset={[-4, 4]}
					onClose={toggleMenu}
					shouldRenderToParent
					strategy="absolute"
					shouldFitContainer
				>
					{() => {
						// The number of visible / non-null menu items so that proper dividers can be added.
						let nonNullMenuItemCount = 0;

						const menuItems = moreMenuItems.map(({ menuId, href }) => {
							if (peekingId && menuId === peekingId) {
								return null; //if an item is peeking, do not render it in this menu
							}

							++nonNullMenuItemCount;

							if (menuId === 'companyHub') {
								const Component = GlobalItemLoadables[menuId];
								if (Component) {
									return (
										<>
											{/* The app shortcuts is visually divided from the preceeding global items: */}
											{nonNullMenuItemCount !== 1 && <Divider />}
											<Box xcss={menuItemStyles}>
												<Component
													key={menuId}
													onClick={() => onClick(menuId)}
													setIsCustomizeSidebarDialogVisible={() => {}}
												/>
											</Box>
										</>
									);
								}
							}

							let node: ReactNode;

							if (href) {
								node = (
									<MenuLinkItem
										href={href}
										onClick={() => onClick(menuId)}
										elemBefore={iconByMenuId[menuId]}
										key={menuId}
									>
										<FormattedMessage {...i18n[menuId]} />
									</MenuLinkItem>
								);
							} else {
								node = (
									<MenuButtonItem
										onClick={() => onClick(menuId)}
										elemBefore={iconByMenuId[menuId]}
										key={menuId}
									>
										<FormattedMessage {...i18n[menuId]} />
									</MenuButtonItem>
								);
							}

							// The item "Customize sidebar" is visually divided from the preceeding global items and/or app shortcuts:
							if (node && fg('company-hub-pseudo-app')) {
								node = <Box xcss={menuItemStyles}>{node}</Box>;

								if (nonNullMenuItemCount !== 1 && menuId === 'customize') {
									node = (
										<>
											<Divider />
											{node}
										</>
									);
								}
							}

							return node;
						});

						if (fg('company-hub-pseudo-app')) {
							// PopupContent has an `xcss` prop, but it casts its value to `string` and thus isn't suitable.
							return <Box xcss={popupContentStyles}>{menuItems}</Box>;
						}

						return menuItems;
					}}
				</PopupContent>
			</Box>
		</Popup>
	);

	// "More" is visually divided from the preceeding global items and/or app shortcuts:
	if (fg('company-hub-pseudo-app')) {
		return (
			<>
				<VisuallyHiddenDivider />
				{popup}
			</>
		);
	}

	return popup;
};

const popupContentContainerStyles = xcss({
	position: 'relative',
	marginInline: 'space.negative.050',
	height: '0',
});

const popupContentStyles = xcss({
	paddingTop: 'space.050',
	paddingBottom: 'space.050',
});

const menuItemStyles = xcss({
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
});
