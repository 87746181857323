import React from 'react';

import { JiraIcon } from '@atlaskit/temp-nav-app-icons/jira';

import { Products } from '../../constants';
import { GlobalShortcutsItem } from '../../types';

export default function jira(
	localizedName: string,
	fallbackUrl: () => string,
): GlobalShortcutsItem {
	return {
		icon: <JiraIcon size="20" />,
		label: localizedName,
		apsKey: Products.JIRA_SOFTWARE,
		fallbackUrl,
	};
}
