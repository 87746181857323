import { defineMessages } from 'react-intl-next';

//ensure that keys all match the menuId type in useMenuItems
export const i18n = defineMessages({
	customHome: {
		id: 'side-navigation.global.home',
		defaultMessage: 'Home',
		description: 'Label text for a link to the user custom home page.',
	},
	forYou: {
		id: 'side-navigation.global.for.you',
		defaultMessage: 'For you',
		description: 'Label text for a link to the home page.',
	},
	companyHub: {
		id: 'side-navigation.global.company.hub',
		defaultMessage: 'Company Hub',
		description:
			'Label text for a link to the special additional premium customized home page which is called "company"',
	},
	recent: {
		id: 'side-navigation.global.recent',
		defaultMessage: 'Recent',
		description: 'Label text for a flyout item title that opens a menu of recently viewed content',
	},
	starred: {
		id: 'side-navigation.global.starred',
		defaultMessage: 'Starred',
		description:
			'Label text for a flyout item title that opens a menu of content the user has "starred" to show they are favorite or most used content',
	},
	spaces: {
		id: 'side-navigation.global.spaces',
		defaultMessage: 'Spaces',
		description:
			'Label text for a flyout item title that opens a menu of "spaces" which are a concept for a grouping of content',
	},
	apps: {
		id: 'side-navigation.global.apps',
		defaultMessage: 'Apps',
		description: 'Label text for an accordion heading that contains 3rd party "apps"',
	},
	goals: {
		id: 'side-navigation.global.goals',
		defaultMessage: 'Goals',
		description: 'Label text for a link to a page that has a users goals on it',
	},
	topics: {
		id: 'side-navigation.global.topics',
		defaultMessage: 'Topics',
		description: 'Label text for a link to a page that has a the theme "topics"',
	},
	teams: {
		id: 'side-navigation.global.teams',
		defaultMessage: 'Teams',
		description:
			'Label text for a link to a page that contains information about the users team members',
	},
	drafts: {
		id: 'side-navigation.global.drafts',
		defaultMessage: 'Drafts',
		description:
			'Label text for a link to a page that contains links to all content the current user has with the draft / unpublished status',
	},
	tasks: {
		id: 'side-navigation.global.tasks',
		defaultMessage: 'Tasks',
		description:
			'Label text for a link to  Page containing a checklist of tasks assigned to the current user',
	},
	more: {
		id: 'side-navigation.global.more',
		defaultMessage: 'More',
		description:
			'Label text for a dropdown, that contains additional / "more" Global navigation items',
	},
	customize: {
		id: 'side-navigation.global.customize.sidebar',
		defaultMessage: 'Customize sidebar',
		description:
			'Label text for a button that opens a dialog that allows the user to change which items appear in the global nav by default & which appear in the more menu',
	},
	templates: {
		id: 'side-navigation.global.customize.templates',
		defaultMessage: 'Templates',
		description:
			'Label text for an link that takes users to a gallery of page templates they can use to draft formatted content in the product.',
	},
	workflows: {
		id: 'side-navigation.global.customize.workflows',
		defaultMessage: 'Workflows',
		description:
			'Label text for an link that takes users to a page containing current workflows and approvals they are a part of.',
	},
	separator: {
		id: 'side-navigation.global.customize.separator',
		defaultMessage: 'HIDE FROM SIDEBAR',
		description:
			'Label text for the separator between which items should appear in the global nav and which should appear in the more menu in the customize sidebar modal',
	},
});
