import React, { memo, type ReactElement } from 'react';

import type { NewCoreIconProps } from '@atlaskit/icon';
import PersonAvatarIcon from '@atlaskit/icon/core/person-avatar';
import HomeIcon from '@atlaskit/icon/core/home';
import OfficeBuildingIcon from '@atlaskit/icon/core/office-building';
import ClockIcon from '@atlaskit/icon/core/clock';
import StarUnstarredIcon from '@atlaskit/icon/core/star-unstarred';
import GlobeIcon from '@atlaskit/icon/core/globe';
import AppsIcon from '@atlaskit/icon/core/apps';
import GoalIcon from '@atlaskit/icon/core/goal';
import HashtagIcon from '@atlaskit/icon/core/hashtag';
import TeamsIcon from '@atlaskit/icon/core/teams';
import EditIcon from '@atlaskit/icon/core/edit';
import TaskIcon from '@atlaskit/icon/core/task';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import FilesIcon from '@atlaskit/icon/core/files';
import DataFlowIcon from '@atlaskit/icon/core/data-flow';
import CustomizeIcon from '@atlaskit/icon/core/customize';

import type { MenuId } from './useMenuItems';

type StyledIconComponentProps = {
	icon: { (props: NewCoreIconProps, displayName: string) };
};

const StyledIconComponent = memo(({ icon }: StyledIconComponentProps) => {
	const IconComponent = icon;
	return <IconComponent label="" color="currentColor" spacing="spacious" />;
});

//Note DST is planning to simplify how icons are passed to Nav 4 icons, & this boilerplate may not always be needed
//these are not behind loadables to prevent a delay in showing the icons. If there is performance regression they could be put behind loadables
export const ForYouIconComponent = <StyledIconComponent icon={PersonAvatarIcon} />;
export const HomeIconComponent = <StyledIconComponent icon={HomeIcon} />;
export const OfficeIconComponent = <StyledIconComponent icon={OfficeBuildingIcon} />;
export const ClockIconComponent = <StyledIconComponent icon={ClockIcon} />;
export const StarIconComponent = <StyledIconComponent icon={StarUnstarredIcon} />;
export const GlobeIconComponent = <StyledIconComponent icon={GlobeIcon} />;
export const AppsIconComponent = <StyledIconComponent icon={AppsIcon} />;
export const GoalIconComponent = <StyledIconComponent icon={GoalIcon} />;
export const HashIconComponent = <StyledIconComponent icon={HashtagIcon} />;
export const TeamsIconComponent = <StyledIconComponent icon={TeamsIcon} />;
export const EditIconComponent = <StyledIconComponent icon={EditIcon} />;
export const TaskIconComponent = <StyledIconComponent icon={TaskIcon} />;
export const TemplatesIconComponent = <StyledIconComponent icon={FilesIcon} />;
export const MoreIconComponent = <StyledIconComponent icon={ShowMoreHorizontalIcon} />;
export const WorkflowsIconComponent = <StyledIconComponent icon={DataFlowIcon} />;
export const CustomizeIconComponent = <StyledIconComponent icon={CustomizeIcon} />;

//ensure that keys all match the menuId type in useMenuItems
export const iconByMenuId: { [key in MenuId]: ReactElement<StyledIconComponentProps> } = {
	customHome: HomeIconComponent,
	yourWork: ForYouIconComponent, // Component has been updated to 'For you', key remains the same.
	companyHub: OfficeIconComponent,
	recent: ClockIconComponent,
	starred: StarIconComponent,
	spaces: GlobeIconComponent,
	apps: AppsIconComponent,
	goals: GoalIconComponent,
	topics: HashIconComponent,
	teams: TeamsIconComponent,
	drafts: EditIconComponent,
	tasks: TaskIconComponent,
	templates: TemplatesIconComponent,
	workflows: WorkflowsIconComponent,
	customize: CustomizeIconComponent,
};
