import React, { useEffect } from 'react';

import { extractClosestEdge } from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { getReorderDestinationIndex } from '@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index';
import { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Stack } from '@atlaskit/primitives';

import { i18n } from '../globalNavigationTranslations';

import type { CustomizableListItem } from './CustomizeSidebarDialog';
import { CustomizeDraggableItem } from './CustomizeDraggableItem';
import { isItemData } from './customizeDraggableData';

type CustomizeDraggableListProps = {
	listItems: CustomizableListItem[];
	onReorderItem: (startIndex: number, finishIndex: number) => void;
};

export function CustomizeDraggableList({ listItems, onReorderItem }: CustomizeDraggableListProps) {
	useEffect(
		() =>
			monitorForElements({
				canMonitor({ source }) {
					return isItemData(source.data);
				},
				onDrop({ location, source }) {
					const target = location.current.dropTargets[0];
					if (!target) {
						return;
					}

					const sourceData = source.data;
					const targetData = target.data;

					if (!isItemData(sourceData) || !isItemData(targetData)) return;

					const startIndex = sourceData.index;
					const closestEdgeOfTarget = extractClosestEdge(targetData);

					const finishIndex = getReorderDestinationIndex({
						startIndex,
						closestEdgeOfTarget,
						indexOfTarget: targetData.index,
						axis: 'vertical',
					});

					// If there would be no change, skip the update.
					if (finishIndex === startIndex) return;

					onReorderItem(startIndex, finishIndex);
				},
			}),
		[onReorderItem],
	);

	return (
		<Stack>
			{listItems.map((item, index) => {
				return (
					<CustomizeDraggableItem
						key={item.id}
						item={item}
						index={index}
						label={i18n[item.id].defaultMessage}
						onReorderItem={onReorderItem}
						numberOfItems={listItems.length}
					/>
				);
			})}
		</Stack>
	);
}
