import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import { withFlags } from '@confluence/flags';

export const CustomizeSidebarDialogErrorFlag = withFlags(({ flags }) => {
	useEffect(() => {
		void flags.showErrorFlag({
			title: <FormattedMessage {...i18n.errorTitle} />,
			description: <FormattedMessage {...i18n.errorDescription} />,
			isAutoDismiss: true,
		});
	}, [flags]);

	return null;
});

const i18n = defineMessages({
	errorTitle: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.error-title',
		defaultMessage: 'We couldn’t save your customizations',
		description:
			'Title for the error flag that shows when global navigation sidebar customization fails to save',
	},
	errorDescription: {
		id: 'side-navigation.global-navigation.customize-sidebar-dialog.error-description',
		defaultMessage:
			'We are unable to save your customizations at this time. Refresh the page or try again.',
		description:
			'Description for the error flag that shows when global navigation sidebar customization fails to save',
	},
});
