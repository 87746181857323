import { useMemo, useContext } from 'react';
import memoize from 'lodash/memoize';

import {
	CONFLUENCE_PEOPLE_DIRECTORY_SEARCH,
	HOME,
	TEMPLATE_GALLERY,
	WORKFLOWS,
} from '@confluence/named-routes';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useIsCompanyHubEnabled } from '@confluence/company-hub-utils/entry-points/useIsCompanyHubEnabled';
import { getCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { useViewUserProfile } from '@confluence/global-operations';
import { useWebItemLocation, SYSTEM_HEADER_LEFT } from '@confluence/web-item-location';
import { fg } from '@confluence/feature-gating';

export type MenuId =
	| 'customHome'
	| 'yourWork'
	| 'companyHub'
	| 'recent'
	| 'starred'
	| 'spaces'
	| 'apps'
	| 'goals'
	| 'topics'
	| 'teams'
	| 'drafts'
	| 'tasks'
	| 'templates'
	| 'workflows'
	| 'customize';

export type MenuItemVisibilityOverrides = Partial<Record<MenuId, boolean>>;
export type GlobalMenuItemConfig = Pick<MenuItemConfig, 'menuId' | 'isCustomizable'>[];
export type MoreMenuItemConfig = Pick<MenuItemConfig, 'menuId' | 'isCustomizable' | 'href'>[];

export type PlatformCustomizationItem = {
	menuId: MenuId;
	visible: boolean;
};

type MenuItemConfig = {
	menuId: MenuId;
	visibility: boolean;
	defaultVisibility: boolean;
	authenticationRequired: boolean;
	isCustomizable: boolean;
	href?: string;
};

type DefaultMenuItemConfig = Pick<
	MenuItemConfig,
	'defaultVisibility' | 'authenticationRequired' | 'isCustomizable' | 'href'
>;

// Default configuration for all possible top-level items in Confluence Global Nav
const defaultMenuConfiguration: Record<MenuId, DefaultMenuItemConfig> = {
	customHome: {
		defaultVisibility: true,
		authenticationRequired: false,
		isCustomizable: false,
	},
	yourWork: {
		defaultVisibility: true,
		authenticationRequired: true,
		isCustomizable: false,
	},
	companyHub: {
		defaultVisibility: true,
		authenticationRequired: true,
		isCustomizable: false,
	},
	recent: {
		defaultVisibility: true,
		authenticationRequired: true,
		isCustomizable: true,
	},
	starred: {
		defaultVisibility: true,
		authenticationRequired: true,
		isCustomizable: true,
	},
	spaces: {
		defaultVisibility: true,
		authenticationRequired: false,
		isCustomizable: true,
	},
	apps: {
		defaultVisibility: true,
		authenticationRequired: false,
		isCustomizable: true,
	},
	goals: {
		//todo: where does this go?
		defaultVisibility: false,
		authenticationRequired: true,
		isCustomizable: true,
		href: '#',
	},
	topics: {
		//todo: where does this go?
		defaultVisibility: false,
		authenticationRequired: false,
		isCustomizable: true,
		href: '#',
	},
	teams: {
		defaultVisibility: false,
		authenticationRequired: true,
		isCustomizable: true,
		href: CONFLUENCE_PEOPLE_DIRECTORY_SEARCH.toUrl(),
	},
	drafts: {
		defaultVisibility: false,
		authenticationRequired: true,
		isCustomizable: true,
		href: HOME.toUrl({ view: 'drafts' }),
	},
	tasks: {
		defaultVisibility: false,
		authenticationRequired: true,
		isCustomizable: true,
		href: HOME.toUrl({ view: 'tasks' }),
	},
	templates: {
		defaultVisibility: false,
		authenticationRequired: false,
		isCustomizable: true,
		href: TEMPLATE_GALLERY.toUrl(),
	},
	workflows: {
		defaultVisibility: false,
		authenticationRequired: true,
		isCustomizable: true,
		href: WORKFLOWS.toUrl(),
	},
	customize: {
		defaultVisibility: false,
		authenticationRequired: true,
		isCustomizable: false,
	},
};

const defaultPlatformCustomizationList: PlatformCustomizationItem[] = Object.entries(
	defaultMenuConfiguration,
)
	.filter(([, { isCustomizable }]) => isCustomizable)
	.map(([menuId, { defaultVisibility }]) => ({
		menuId: menuId as MenuId,
		visible: defaultVisibility,
	}));

const mergeCustomizationsWithDefaults = memoize(
	(platformCustomizations?: PlatformCustomizationItem[]) => {
		const validatedCustomizations: PlatformCustomizationItem[] = [];
		const customizedMenuIds = new Set();

		platformCustomizations?.forEach((item) => {
			if (defaultMenuConfiguration[item.menuId as MenuId]) {
				validatedCustomizations.push(item);
				customizedMenuIds.add(item.menuId);
			}
		});

		// These items always appear first
		const finalMenuItems: PlatformCustomizationItem[] = [
			{ menuId: 'customHome', visible: true },
			{ menuId: 'yourWork', visible: true },
		];

		const companyHubMenuItem: PlatformCustomizationItem = { menuId: 'companyHub', visible: true };
		if (!fg('company-hub-pseudo-app')) {
			// Company Hub was a global item before Team '25 US.
			finalMenuItems.push(companyHubMenuItem);
		}

		// Add any customized menu items
		validatedCustomizations.forEach((item) => {
			finalMenuItems.push(item);
		});

		// Add any default items that were not customized
		defaultPlatformCustomizationList.forEach((item) => {
			if (!customizedMenuIds.has(item.menuId)) {
				finalMenuItems.push(item);
			}
		});

		if (fg('company-hub-pseudo-app')) {
			// Company Hub is an app shortcut at Team '25 US.
			finalMenuItems.push(companyHubMenuItem);
		}

		// Customize always appears last
		finalMenuItems.push({ menuId: 'customize', visible: true });

		return finalMenuItems;
	},
);

const createMenuItem = (menuId: MenuId, config: DefaultMenuItemConfig) => ({
	menuId,
	isCustomizable: config.isCustomizable,
	...(config.href && { href: config.href }),
});

// create lists of menu items based on specific filters and conditions
const generateMenuItemLists = (
	isPermitted: boolean,
	platformCustomizations?: PlatformCustomizationItem[],
	visibilityOverrides?: MenuItemVisibilityOverrides,
	withVisibleAppShortcuts?: boolean,
) => {
	const globalMenuItems: GlobalMenuItemConfig = [];
	const moreMenuItems: MoreMenuItemConfig = [];

	const orderedItems = mergeCustomizationsWithDefaults(platformCustomizations);
	const customizeItemId: MenuId = 'customize';

	orderedItems.forEach((item) => {
		const menuId = item.menuId as MenuId;
		const defaultConfig = defaultMenuConfiguration[menuId];

		if (
			!(defaultConfig.authenticationRequired && !isPermitted) &&
			visibilityOverrides?.[menuId] !== false &&
			menuId !== customizeItemId
		) {
			let visible = item.visible;
			// Before cutomization for app shortcuts arrives, are collapsed into More when the users are in a space, because:
			// * the apps such as Company Hub could be distracting to users when they are working inside a space;
			// * as more app shortcuts are added and made visible, soon it'll be hard to scale.
			if (menuId === 'companyHub' && !withVisibleAppShortcuts && fg('company-hub-pseudo-app')) {
				visible = false;
			}

			const menuItem = createMenuItem(menuId, defaultConfig);
			(visible ? globalMenuItems : moreMenuItems).push(menuItem);
		}
	});

	// customize sidebar item should be the last item of the more items menu
	// unless there are no items, then it should be in the global menu items
	if (isPermitted && visibilityOverrides?.[customizeItemId] !== false) {
		const customizeItem = createMenuItem(
			customizeItemId,
			defaultMenuConfiguration[customizeItemId],
		);
		(moreMenuItems.length === 0 ? globalMenuItems : moreMenuItems).push(customizeItem);
	}

	return {
		globalMenuItems,
		moreMenuItems,
	};
};

export const useMenuItems = (
	isPermitted: boolean,
	platformCustomizations?: PlatformCustomizationItem[],
	withVisibleAppShortcuts?: boolean,
) => {
	const { loading: webItemsLoading, webItems } = useWebItemLocation({
		location: SYSTEM_HEADER_LEFT,
		allowedWebItems: [SPACE_DIRECTORY_KEY, PEOPLE_DIRECTORY_KEY],
	});
	const { isCompanyHubEntryPointEnabled } = useIsCompanyHubEnabled();
	const { canViewUserProfile: hasViewUserProfilePermission } = useViewUserProfile();
	const { homepageUri, frontCoverState, loading } = useContext(SPAViewContext);

	const isSpacesVisible =
		!webItemsLoading && !!webItems.find((item) => item.completeKey === SPACE_DIRECTORY_KEY);
	const isPeopleVisible =
		!webItemsLoading &&
		(hasViewUserProfilePermission ||
			!!webItems.find((item) => item.completeKey === PEOPLE_DIRECTORY_KEY));
	const isCompanyHubVisible = !loading && isCompanyHubEntryPointEnabled && !!frontCoverState;

	const isCompanyHubSetAsCustomHome =
		isCompanyHubVisible && homepageUri.includes(getCompanyHubSpaceKey());
	const isCustomHomeVisible: boolean =
		!isCompanyHubSetAsCustomHome && !['/spa.action', '', 'homepageUri'].includes(homepageUri);

	return useMemo(() => {
		// remove elements from the returned data structures based on application state and override defaults and customization store
		// eslint-disable-next-line confluence-feature-gating/inline-usage
		const visibilityOverrides: MenuItemVisibilityOverrides = {
			customHome: isCustomHomeVisible,
			companyHub: isCompanyHubVisible,
			spaces: isSpacesVisible,
			teams: isPeopleVisible,
			topics: false, // todo: remove when platform tells us what topics does / goes
			goals: false, // todo: remove when platform tells us what goals does / goes
			// Hide Drafts tab when live edit is enabled for the site
			drafts: !fg('confluence_live_pages_ab_test_opted_in'),
			// Hide Drafts, Tasks, and Templates for nav 4 beta
			tasks: !fg('confluence_nav_4_beta'),
			templates: !fg('confluence_nav_4_beta'),
			workflows: fg('document_workflows'),
			customize: fg('confluence_nav_4_beta'),
		};

		return generateMenuItemLists(
			isPermitted,
			platformCustomizations,
			visibilityOverrides,
			withVisibleAppShortcuts,
		);
	}, [
		isCustomHomeVisible,
		isCompanyHubVisible,
		isSpacesVisible,
		isPeopleVisible,
		isPermitted,
		platformCustomizations,
		withVisibleAppShortcuts,
	]);
};

export const SPACE_DIRECTORY_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-spaces-link';
export const PEOPLE_DIRECTORY_KEY =
	'com.atlassian.confluence.plugins.confluence-space-ia:header-people-link';
